.elemento-filho {
    scroll-snap-align: start;
}

html {
    scroll-snap-type: y mandatory;
    // scroll-behavior: smooth;
}

section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

