.internal-screen {
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: #820AD1;
    height: 90vh;

    .bg-img {
        z-index: 1;
        overflow: hidden;
        height: 100%;

        img {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    .screen-container {
        display: flex;
        flex: 1;
        max-width: 1440px;
        flex-direction: column;
        z-index: 2;
        position: absolute;
        height: 90vh;


        .project-top {
            display: flex;
            flex-direction: column;
            padding: 64px 64px 64px 64px;
            gap: 120px;
            flex: 1;
            max-width: 1440px;
            justify-content: space-between;


            .header {
                display: flex;
                justify-content: space-between;

                img {
                    height: 64px;
                }

                .back-button {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    opacity: .6;
                    transition: all 300ms;

                    &:hover {
                        opacity: 1;
                    }
                }

                h2 {
                    color: var(--color-secondary);
                }
            }

            .project-info {

                display: flex;
                gap: 72px;
                padding: 32px;
                border-radius: 16px;


                .project-title {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 24px;

                    h1,
                    h2,
                    h3 {
                        color: var(--color-secondary);
                    }
                }

                .project-text {
                    display: flex;
                    flex: 1;
                    color: var(--color-secondary);

                }
            }
        }
    }
}

    @media screen and (max-width: 600px) {

    .internal-screen {

        .screen-container {

            .project-top {
                padding: 20px;

                .header {

                    padding: 16px;

                    img {
                        height: 32px;
                    }

                    .back-button {
                        gap: 4px;

                        svg {
                            width: 100px;
                        }
                    }
                }

                .project-info {
                    flex-direction: column;
                    gap: 32px;

                    .project-title {

                        h1,
                        h2,
                        h3 {
                            color: var(--color-secondary);
                        }
                        
                    }

                    .project-text {
                        h4 {
                           font-size: 24px;
                           line-height: 30px; 
                        }
                    }
                }
            }
        }
    }

}