html {
  font-size: calc(min(max(1vw, 12px), 16px));
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --cursor-main: url('./assets/svg/cursor.svg'), auto;
  --cursor-hover: url('./assets/svg/cursor-hover.svg'), auto;
  --cursor-loading: url('./assets/mp4/cursor.gif') 0 0, auto;
  --color-primary: black;
  --color-secondary: white;
  margin: 0;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    display: unset;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

  }
}

h1 {
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -1.92px;
}

h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.72px;
}

h3 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 116%;
  letter-spacing: -0.6px;
}

h4 {
  font-size: 2.14rem;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  letter-spacing: -1.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



// @media screen and (max-width: 1124px) {
//   html {
//     font-size: 14px;
//   }
// }

//   body {
//     h1 {
//       font-size: 56px;
//       line-height: 56px;
//     }

//     h2 {
//       font-size: 20px;
//       line-height: 24px;
//     }

//     h3 {
//       font-size: 18px;
//       line-height: 18px;
//     }

//     h4 {
//       font-size: 24px;
//       line-height: 28px;
//     }
//   }
// }

@media screen and (max-width: 600px) {

  body {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    h2 {
      font-size: 18px;
      line-height: 20px;
    }

    h3 {
      font-size: 16px;
      line-height: 16px;
    }

    h4 {
      font-size: 32px;
      line-height: 40px;
    }
  }

}