@font-face {
  font-family: 'Fontspring-DEMO-integralcf-regular';
  src: url(../../assets/font/Fontspring-DEMO-integralcf-regular.otf) format('opentype');
}

a {
  text-decoration: none;
  color: unset;
}

.bg-blur {
  z-index: -2;
  position: absolute;
  background-color: #0f0f0f;
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/png/bg-grain.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  }

.grain-container {
  z-index: -1;
  position: absolute;
  width: 150vw;
  height: 150vw;
  right: -80vw;
  bottom: -50vh;
  opacity: .6;
  -webkit-mask: radial-gradient(red, transparent, transparent);
  mask: radial-gradient(red, transparent, transparent);
  filter: url(#grain) grayscale(1);
  background-color: rgb(255, 255, 255);
  animation: identifier 120s linear infinite alternate;
}

.title-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    height: 70vh;
  }

  .title-name {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .line-1 {
      margin-left: -16px;
      z-index: -1;
    }

    .line-2 {
      margin-left: -16px;
    }

    .line-3 {
      margin-left: -120px;
      display: flex;
      align-items: flex-end;
      gap: 20px;

      .clients-container {
        display: flex;
        flex-direction: column;

        .item-container:hover {
          color: #05FF00
        }

        h3 {
          font-family: 'Fontspring-DEMO-integralcf-regular', sans-serif;
          color: var(--color-secondary);
          font-size: 20px;
          gap: 0;
          line-height: 100%;
          font-weight: 400;
        }
      }
    }

    .line-4 {
      margin-left: -16px;
    }

    .line-castro {
      display: flex;
      gap: 16px;
      align-items: flex-end;

      img {
        height: 80px;
        width: 80px;
      }
    }

    h1 {
      color: var(--color-secondary);
      font-family: 'Fontspring-DEMO-integralcf-regular', sans-serif;
      font-size: 7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
}

.presentation-screen-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: var(--cursor-main);

  .circle {
    position: relative;

    img {
      position: absolute;
      left: 80vw;
      top: 75vh;
      width: 320px;
      height: 320px;
    }
  }

  .presentation-screen {
    flex: 1;
    display: flex;
    height: 100vh;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;
    max-width: 1440px;

    .menu-container {
      display: flex;
      width: 100%;
    }

    .main-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      padding: 0px 64px;
      width: calc(100% - 128px);



      .title-main-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;

        h1 {
          color: var(--color-primary);
        }
      }

      .photo-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        border-top: solid 4px black;
        width: 100%;

        img {
          width: 800px;
          max-width: 80%;
          min-width: 260px;
        }
      }
    }

    .div-spacer {
      height: 40px;
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 700px) {

  .title-name-container {
    img {
      width: 30vw;
      height: 50vh;
      object-fit: contain;
    }

    .title-name {
      h1 {
        font-size: 4rem;
      }

      .line-3 {
        margin-left: -32px;

        .clients-container {
          display: none;

          .item-container:hover {
            color: none;
          }
        }
      }

      .line-castro {
        gap: 8px;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .presentation-screen-container {
    .circle {
      img {
        left: 50vw;
        top: 60vh;
      }
    }

    .presentation-screen {
      flex-direction: column-reverse;
      justify-content: flex-end;

      .main-content {
        padding: 0px 24px 32px 24px;

        .title-main-container {
          align-items: flex-start;
          flex-direction: column-reverse;
          gap: 40px;

          img {
            width: 32px;
          }

          .div-spacer {
            display: none;
          }
        }
      }
    }
  }
}