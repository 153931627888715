.loading-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #000000;
    cursor: var(--cursor-main);

    h2 {
        font-family: 'Fontspring-DEMO-integralcf-regular';
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        color: #05FF00;
    }
}

@font-face {
    font-family: 'Fontspring-DEMO-integralcf-regular';
    src: url(../../assets/font/Fontspring-DEMO-integralcf-regular.otf) format('opentype');
}