.other-projects {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    .menu-container {
        display: flex;
    }


    .project-list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 56px;
        flex: 1;
        margin-bottom: 100px;


        .project-list {
            display: flex;
            gap: 40px;


            .list-item-container {
                position: relative;
                overflow: hidden;
                border-radius: 16px;
                height: 220px;
                width: 220px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                transition: all 300ms;


                h2 {
                    position: absolute;
                    z-index: 2;
                    text-align: center;
                    color: var(--color-secondary);
                    transition: all 300ms;
                    opacity: 1;



                }

                .project-item {
                    width: 260px;
                    position: absolute;
                    z-index: 1;
                    transition: all 200ms ease-in-out;



                }

                &:hover {


                    .project-item {
                        transform: scale(.9);  
                        filter: blur(6px);                      
                    }

                    h2 {
                        // transform: translateY(50px);
                        transform: scale(1.15);
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 600px) {

    .other-projects {
        flex-direction: column-reverse;
        height: auto;
    
        .project-list-container {

            padding: 64px 24px;
            justify-content: flex-start;
            margin: 0;
        

            h1 {
                text-align: center;
            }

            .project-list {
                flex-wrap: wrap;
                gap: 16px;
                justify-content: center;

    
                .list-item-container {

                    height: 140px;
                    width: 140px;
                    transition: none;

      
                    .project-item {
                        width: 180px;
                    }

                    &:hover {


                        .project-item {
                            transform: none;  
                            filter: none; 
                            transition: none;
                     
                        }
    
                        h2 {
                            transform: none;
                        }
                    }

                    &:active {
                        .project-item {
                            filter: blur(6px);                      
                        }


                    }

    
                }
            }
        }
    }
    
}