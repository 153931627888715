p {
    margin: unset;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;

    li {
        margin: 0;
        padding: 0;
        text-decoration: none;
    }
}

.new-app-container {
    display: flex;
    flex-direction: column;
    padding: 72px 64px;
    align-items: center;
    gap: 72px;
    background-color: #f1f1f1;
    

    .rebrand-arrow {
        display: flex;
        color: #FA00FF;
        align-items: center;
        gap: 16px;
        height: fit-content;
        padding-top: 24px;
        margin: 0px -96px;
    }

    .new-app-imgs {
        display: flex;

        .new-app-title {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
        }

        .new-app-item {
            display: flex;
            flex-direction: column;
            gap: 24px;


            img.app-icon {
                width: 64px;
                height: fit-content;
                padding: unset;
            }

            img {
                padding-left: 32px;
                height: 70vh;
                width: fit-content;
                object-fit: contain;
                
            }

            p {
                font-size: 16px;
                font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
            }
        }
    }
}

.design-system-container {
    display: flex;
    flex-direction: column;
    padding: 72px 64px;
    gap: 64px;
    color: var(--color-secondary);
    flex: 1;


    .design-system-subtitle {

        padding-top: 32px;
        border-top: 2px solid #FA00FF;
        display: flex;
        flex-direction: column;
        gap: 12px;

        p {
            color: #858585;
        }


        .figma-tokens {
            padding-top: 48px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            img {
                width: 700px;
                height: fit-content;
            }

            img.push {
                width: 160px;
            }
        }
    }

    .design-system-topics {

        display: flex;
        padding: 64px 0px;
        gap: 104px;
        justify-content: space-between;


        img {
            width: fit-content;
            height: 72px;
            object-fit: contain;
        }

        .topic-item {
            display: flex;
            flex-direction: column;
            gap: 56px;
            justify-content: flex-start;

            .topic-item-title {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }

        .topic-item.tokens-container {
            ul {
                display: flex;
                flex-direction: column;
                gap: 12px;

                li {
                    padding: 8px 12px;
                    background-color: var(--color-secondary);
                    border-radius: 24px;
                    color: var(--color-primary);
                    width: fit-content;
                    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                    font-size: 16px;
                }
            }
        }

        .topic-item {

            .components-imgs {
                display: flex;
                flex-direction: column;
                gap: 24px;

                img {
                    height: fit-content;
                    width: 260px;
                }
            }
        }

    }
}


.modular-home-container {
    display: flex;
    padding: 64px;
    flex: 1;
    justify-content: space-between;
    gap: 30%;

    .modular-home-title {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    video {
        height: 90vh;
        border-radius: 12px;
    }
}

.branding-container {
    display: flex;
    flex-direction: column;
    padding: 64px;
    gap: 64px;
    flex: 1;

    p {
        color: #828282;
    }

    .branding-title {
        display: flex;
        flex-direction: column;
        gap: 24px;
        color: var(--color-secondary);
    }

    .illustration-imgs {
        display: flex;
        gap: 24px;
        flex: 1;
        align-items: center;

        img {
            height: fit-content;
            width: 180px;
            object-fit: contain;
        }
    }

    svg {
        width: 100%;
        display: flex;
        flex: 1;
    }

    .animation-container {
        display: flex;
        justify-content: space-between;

        .video-container-uc {
            display: flex;
            gap: 24px;

            video {
                height: 80vh;
            }
        }
    }

}

.video-general-ugly {
    width: calc(100vw - 128px);
    background-color: black;
    padding: 64px;
    justify-content: center;
    align-items: center;
    display: flex;

    video {
        height: 80vh;
    }
}

@media screen and (max-width: 600px) {

    .new-app-container {
        padding: 40px;
        gap: 32px;

        .rebrand-arrow {
            display: none;
        }

        h4 {
            text-align: center;
        }

        .new-app-imgs {
            flex-direction: column;
            gap: 24px;

            .new-app-title {
                gap: 16px;
            }

            .new-app-item.reserve {
                display: none;
            }

            .new-app-item {
                gap: 8px;

                img {
                    width: 90vw;
                    height: fit-content;
                }
            }
        }
    }



    .design-system-container {
        padding: 64px 32px;
        gap: 32px;
        width: calc(100vw - 64px);

        .design-system-subtitle {
            .figma-tokens {
                padding-top: 32px;

                img {
                    width: calc(100vw - 64px);
                    height: fit-content;
                }

                img.push {
                    width: 160px;
                }
            }
        }

        .design-system-topics {
            flex-direction: column;
            gap: 64px;

            .topic-item.template-container {
                border: none;
                padding: 0;
            }

            img {
                width: 72px;
                height: auto;
            }

            .topic-item {

                padding-bottom: 64px;
                border-bottom: 2px solid #828282;

                .topic-item-title {
                    gap: 16px;
                }

                .components-imgs {
                    flex-direction: column;
                    gap: 16px;

                    img {
                        width: 60vw;
                    }
                }
            }
        }
    }

    .modular-home-container {
        padding: 64px 32px;
        gap: 32px;
        flex-direction: column;

        .modular-home-title {
            gap: 16px;
        }

        video {
            height: fit-content;
            width: calc(100vw - 64px);
        }
    }

    .branding-container {
        padding: 64px 32px;
        gap: 40px;

        .branding-title {
            gap: 16px;
        }

        .illustration-imgs {
            display: grid;
            gap: 16px;
            flex: unset;
            grid-template-columns: repeat(auto-fill, 80px);

            img {
                width: 80px;
                height: auto;
            }
        }

        .animation-container {
            flex-direction: column;
            gap: 32px;

            .video-container-uc {
                flex-direction: column;
                gap: 24px;
                align-items: flex-start;

                video {
                    height: 60vh;
                }
            }
        }
    }

    .video-general-ugly {
        width: calc(100vw - 64px);
        padding: 64px 32px;
        gap: 32px;

        video {
            height: auto;
            width: 85%;
        }
    }
}